const stateMapping = {
  Alabama: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  Alaska: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  Arizona: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm', 'betrivers', 'superbook', 'unibet_us', 'wynnbet'],
  Arkansas: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  California: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  Colorado: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm', 'betrivers', 'superbook'],
  Connecticut: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel'],
  Delaware: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  Florida: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'williamhill_us'],
  Georgia: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  Hawaii: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  Idaho: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  Illinois: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm', 'betrivers', 'pointsbetus'],
  Indiana: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm', 'betrivers', 'pointsbetus', 'unibet_us'],
  Iowa: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm', 'betrivers', 'pointsbetus', 'superbook'],
  Kansas: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm', 'pointsbetus'],
  Kentucky: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm'],
  Louisiana: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm', 'betrivers', 'pointsbetus'],
  Maine: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings'],
  Maryland: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm', 'betrivers', 'pointsbetus', 'superbook'],
  Massachusetts: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm'],
  Michigan: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm', 'betrivers', 'pointsbetus', 'wynnbet'],
  Minnesota: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  Mississippi: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'fanduel', 'betmgm'],
  Missouri: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  Montana: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  Nebraska: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  Nevada: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'fanduel', 'betmgm', 'williamhill_us'],
  'New Hampshire': ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings'],
  'New Jersey': ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm', 'betrivers', 'pointsbetus', 'superbook', 'unibet_us'],
  'New Mexico': ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  'New York': ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm', 'betrivers', 'pointsbetus', 'wynnbet'],
  'North Carolina': ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm'],
  'North Dakota': ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  Ohio: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm', 'betrivers', 'pointsbetus', 'superbook'],
  Oklahoma: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  Oregon: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'betmgm'],
  Pennsylvania: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm', 'betrivers', 'pointsbetus', 'unibet_us'],
  'Rhode Island': ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  'South Carolina': ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  'South Dakota': ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'betmgm'],
  Tennessee: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm', 'superbook'],
  Texas: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  Utah: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  Vermont: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel'],
  Virginia: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm', 'betrivers', 'unibet_us'],
  Washington: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'fanduel', 'betmgm'],
  'West Virginia': ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm', 'betrivers'],
  Wisconsin: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag'],
  Wyoming: ['bettoredge', 'betonlineag', 'betus', 'bovada', 'lowvig', 'mybookieag', 'draftkings', 'fanduel', 'betmgm']
};

export default stateMapping;
